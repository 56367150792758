<template>
  <div class="clock-in-logs">
    <h :isback="true">
      <span class="name">打卡记录</span>
    </h>
    <div class="book">
      <div class="content">
        <div class="title">我的战绩</div>
        <div class="data">
          <div class="item">
            <div class="num">{{ dppPay }}</div>
            <div class="desc">累计投入(斗币)</div>
          </div>
          <div class="item">
            <div class="num">{{ dppCount }}</div>
            <div class="desc">累计获得(斗币)</div>
          </div>
          <div class="item">
            <div class="num">{{ count }}</div>
            <div class="desc">成功打卡(次数)</div>
          </div>
        </div>
      </div>
    </div>
    <div class="logs-title">战绩明细</div>
    <div v-if="!hasData" class="not-data">
      <div class="image"><img src="./img/no-data.png" alt="暂无数据" /></div>
      <div class="text">暂无战绩</div>
      <div class="text">快去参加挑战吧~</div>
    </div>
    <div v-else class="history-data">
      <cube-scroll
        ref="scroll"
        :data="list"
        :options="options"
        @pulling-up="onPullingUp"
      >
        <ul class="list">
          <li
            v-for="item in list"
            :key="item.id"
            class="item border-bottom-1px"
          >
            <div class="date">{{ item.clockTime }}</div>
            <div class="state">{{ state[item.status] }}</div>
            <div v-show="item.status == 0" class="desc">记得打卡哦</div>
            <div v-show="item.status == 1" class="desc">
              斗币+{{ item.avgDppRewards }}
            </div>
            <div v-show="item.status == 2" class="desc">斗币-200</div>
            <div v-show="item.status == 3" class="desc">待瓜分奖励</div>
          </li>
        </ul>
      </cube-scroll>
    </div>
  </div>
</template>
<script>
import h from "@/components/base/header";
import { getClockHistoryList } from "@/api/clockin";
export default {
  components: {
    h
  },
  data() {
    return {
      hasData: true,
      options: {
        click: true,
        pullUpLoad: {
          threshold: 50,
          txt: {
            more: "",
            noMore: "没有更多了"
          }
        }
      },
      dppCount: 0,
      dppPay: 0,
      count: 0,
      list: [],
      pageSize: 20,
      pageNumber: 1,
      state: [
        "待打卡",
        "打卡成功",
        "打卡失败",
        "待瓜分奖金"
      ] /**状态0待打卡1打卡成功2打卡失败3待瓜分奖金状态 */
    };
  },
  created() {
    this._getClockHistoryList();
  },
  methods: {
    async _getClockHistoryList() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      const res = await getClockHistoryList(params);
      if (!res) return;
      const data = res.object;
      this.list = [...this.list, ...data.liveUserSignRecords];
      this.dppCount = data.sumRewards;
      this.dppPay = data.sumClockDpp;
      this.count = data.count;
      this.hasData = this.list.length;
      this.hasMoreData = data.liveUserSignRecords.length >= this.pageSize;
    },
    onPullingUp() {
      if (!this.hasMoreData) {
        this.$refs.scroll.forceUpdate();
        return;
      }
      this.pageNumber += 1;
      this._getClockHistoryList();
    },
    onPullingDown() {
      this.pageNumber = 1;
      this._getClockHistoryList();
    }
  }
};
</script>
<style lang="stylus" scoped>
.clock-in-logs
  position relative
  width 100%
  height 100%
  background #fff
  z-index 11
  .book
    position relative
    width 100%
    height 153px
    overflow hidden
    &:before
      position absolute
      display block
      content ""
      top 0
      right 0
      left 0
      height 97px
      background #2561CE
    .content
      position relative
      width 370px
      height 138px
      margin 13px auto 0
      padding 0 26px
      background #fff
      border-radius 12px
      box-shadow 0px 2px 2px 0px rgba(6, 6, 6, 0.3)
      color #383A40
      .title
        padding 22px 0 26px
        font-size 15px
        font-weight bold
      .data
        display flex
        .item
          flex 1
          .num
            padding-bottom 14px
            font-size 25px
            font-weight bold
          .desc
            font-size 10px
  .logs-title
    padding 21px 24px
    font-size 15px
    color #383A40
  .not-data
    text-align center
    .image
      padding 30px 0 29px
      img
        width 144px
    .text
      font-size 19px
      color #BCBCBC
  .history-data
    position absolute
    top 246px
    right 0
    bottom 0
    left 0
    overflow hidden
    .list
      padding 0 24px
      .item
        display flex
        padding 15px 0 9px
        &:first-child
          padding-top 0
        .date,.state,.desc
          flex 1
          font-size 15px
          color #969696
</style>
