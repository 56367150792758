import axios from "@/axios";
/**
 * 获取签到页面配置信息
 */
export function getClockInConfig() {
  const url = `live_sign/signIndex.json`;
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 签到
 * type: 1签到2报名3打卡
 * day: 签到礼物盒特殊状态 普通传1,其余第7天传7，第21天传21，第30天传30
 */
export function clockin(params) {
  const url = `live_sign/sign_in.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取签到记录
 */
export function getSigninHistoryList(params) {
  const url = `live_sign/signRecords.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取打卡记录
 */
export function getClockHistoryList(params) {
  const url = `live_sign/clockInRecords.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
